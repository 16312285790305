/* @import url('https://fonts.googleapis.com/css2?family=Tapestry&display=swap'); */


html,
body,
#root,
.scroll {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  /* user-select: none; */
  overflow: hidden;
}

.scroll {
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  /* scroll-snap-type: y proximity; */
}



@tailwind base;
@tailwind components;
@tailwind utilities;